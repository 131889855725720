<template>
  <div class="header">
    <div class="logo">
      <a href="https://www.foxtrot.com.ua/uk">
        <svg width="160" height="27" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M17.2 2.3h-.9V0h-5.5v2.3h-1c-6 0-9.8 3-9.8 8.4v1C0 17 3.7 20 9.9 20h.9v2.3h5.5V20h.9c6.1 0 9.9-3 9.9-8.4v-1c0-5.5-3.8-8.4-9.9-8.4zM9.6 16.1c-2.7 0-4.1-1.7-4.1-4.7V11c0-3 1.4-4.7 4.1-4.7h1.2V16H9.6zm12-4.7c0 3-1.5 4.7-4.2 4.7h-1.1V6.3h1.1c2.7 0 4.2 1.6 4.2 4.7v.4zM38.3 6.4c-5.3 0-8.9 3.3-8.9 8 0 4.8 3.6 8 8.9 8 5.3 0 8.9-3.2 8.9-8 0-4.7-3.6-8-8.9-8zm0 12c-2.3 0-3.8-1.6-3.8-4s1.5-4 3.8-4c2.2 0 3.8 1.6 3.8 4s-1.6 4-3.8 4zM67.3 6.7h-5.8l-6 7.2V6.7h-5v15.4h5v-7.2l6 7.2h6.2l-7-8 6.6-7.4zM76.8 10.4c1.7 0 2.9 1 3.3 2.4h5c-.4-3.8-3.5-6.4-8.2-6.4-5.3 0-8.9 3.3-8.9 8 0 4.8 3.6 8 9 8 4.6 0 7.7-2.6 8-6.3h-4.9a3.3 3.3 0 0 1-3.3 2.4c-2.2 0-3.7-1.7-3.7-4 0-2.5 1.5-4.1 3.7-4.1zM86.4 6.7v4.1H92v11.3h5V10.8h5.5v-4h-16zM114.7 6.6c-2 0-3.8.8-5 2.3V6.7H105v20h5v-6.4c1.2 1.2 2.8 2 4.7 2 4.6 0 7.7-3.3 7.7-7.9 0-4.6-3.1-7.8-7.7-7.8zm-1.2 11.9c-2.2 0-3.8-1.7-3.8-4 0-2.5 1.6-4.1 3.8-4.1 2.3 0 3.8 1.6 3.8 4s-1.5 4-3.8 4zM133.7 6.4c-5.3 0-8.9 3.3-8.9 8 0 4.8 3.6 8 8.9 8 5.3 0 8.9-3.2 8.9-8 0-4.7-3.6-8-9-8zm0 12c-2.3 0-3.8-1.6-3.8-4s1.5-4 3.8-4c2.2 0 3.8 1.6 3.8 4s-1.6 4-3.8 4zM144 6.7v4.1h5.5v11.3h5V10.8h5.5v-4h-16z"
            fill="#fff"/>
        </svg>
      </a>


      <div class="time">
  <span>Час доставки: {{estimateResult}}</span>
      </div>
    </div>
    <div class="info-link">
      <router-link :to="`/courier?hash=${this.$route.query.hash}`"
                   class="el-button el-button--default el-button--small is-round">Інформація про кур'єра
      </router-link>
    </div>

  </div>
</template>

<script>
  export default {
    name: "Header",
    props: {
      estimate: {
        type: String,
        default: null
      }
    },
    mounted() {
    },
    computed: {
      estimateResult() {
        return this.estimate === "null" ? "--" : this.estimate
      }
    }


  }
</script>

<style scoped lang="scss">
  .header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: #e95d2a;
    height: 100px;
    position: relative;
    z-index: 2;

    .logo {
      text-align: center;
    }

    .time {
      position: relative;
      font-size: 16px;
      font-weight: 600;
      color: #ffffff;


      right: -10px;
      top: 0;


    }
  }

</style>
